import { Button } from "@bleu.builders/ui";
import Dialog from "@components/ui/Dialog";
import { useProgram } from "@contexts/ProgramContext";
import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

import { ChallengeData } from "../types";

export function ExtendedProfileModal() {
  const program = useProgram();
  const [isOpen, setIsOpen] = React.useState(true);
  const navigate = useNavigate();
  const { extendedProfileChallenge } = useLoaderData() as {
    extendedProfileChallenge: ChallengeData;
  };

  if (
    !extendedProfileChallenge ||
    extendedProfileChallenge.completed_by_current_participant_on
  )
    return null;

  const award =
    extendedProfileChallenge?.reward_type === "prize"
      ? `${program.term_rewards_as}`
      : extendedProfileChallenge?.points > 0
        ? `${extendedProfileChallenge?.points} more ${program?.term_points_as}`
        : `more ${program?.term_points_as}`;

  return (
    //  @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
    <Dialog.Root defaultOpen={true} open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Title></Dialog.Title>
      <Dialog.Content className="sm:max-w-[1000px] p-6 gap-4">
        <div className="text-center flex flex-col">
          <span className="mt-5">Complete your profile to earn {award}!</span>
        </div>
        <div className="flex flex-col gap-3 w-full">
          <Button
            className="w-full py-2 text-base font-medium bg-perk-header-bg hover:bg-perk-header-bg/80 text-perk-primary-content extended-profile-text"
            onClick={() =>
              navigate(`/challenge/${extendedProfileChallenge.identifier}`)
            }
          >
            Complete profile
          </Button>
          <Button
            variant="outline"
            className="w-full py-2 text-base font-medium hover:shadow-md"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Skip
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
