import {
  BaseField,
  Button,
  cn,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
  withConditional,
} from "@bleu.builders/ui";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";

import { ScrollArea } from "./ui/ScrollArea";

const DatePickerCalendar = ({ value, onChange, isDatetime }) => {
  const initialDate = value ? new Date(value) : new Date();
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [yearOpen, setYearOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const yearRef = useRef(null);
  const monthRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (yearRef.current && !yearRef.current.contains(event.target)) {
        setYearOpen(false);
      }
      if (monthRef.current && !monthRef.current.contains(event.target)) {
        setMonthOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [yearRef, monthRef]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(
    { length: new Date().getFullYear() - 1950 },
    (_, i) => 1950 + i,
  );

  const handleMonthChange = (monthIndex) => {
    const newDate = new Date(currentDate.setMonth(monthIndex));
    setCurrentDate(newDate);
  };

  const handleYearChange = (year) => {
    const newDate = new Date(currentDate.setFullYear(Number(year)));
    setCurrentDate(newDate);
  };

  const handleDateSelect = (date) => {
    if (isDatetime && value) {
      // Preserve time when selecting new date
      const existingDate = new Date(value);
      date.setHours(existingDate.getHours());
      date.setMinutes(existingDate.getMinutes());
    }
    onChange(date);
  };

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const renderCalendar = () => {
    const days = [];
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDay = getFirstDayOfMonth(currentDate);

    // Previous month days
    for (let i = 0; i < firstDay; i++) {
      days.push(
        <div key={`empty-${i}`} className="text-gray-300 p-2 text-center">
          {new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            0,
          ).getDate() -
            (firstDay - i - 1)}
        </div>,
      );
    }

    // Current month days
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDateValue = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        i,
      );
      const isSelected =
        value &&
        i === new Date(value).getDate() &&
        currentDate.getMonth() === new Date(value).getMonth() &&
        currentDate.getFullYear() === new Date(value).getFullYear();

      days.push(
        <div
          key={i}
          onClick={() => handleDateSelect(currentDateValue)}
          className={`p-2 text-sm text-center cursor-pointer hover:bg-gray-50 ${
            isSelected ? "bg-blue-50 text-blue-600 rounded-md" : ""
          }`}
        >
          {i}
        </div>,
      );
    }

    // Next month days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push(
        <div key={`next-${i}`} className="text-gray-300 p-2 text-center">
          {i}
        </div>,
      );
    }

    return days;
  };

  return (
    <div className="w-full">
      <div className="flex gap-2 mb-4">
        <div className="relative w-32" ref={monthRef}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setMonthOpen(!monthOpen);
            }}
            className="w-full p-2 pl-4 pr-8 rounded-lg border border-gray-200 bg-white cursor-pointer focus:outline-none focus:border-gray-300 flex items-center justify-between"
          >
            {months[currentDate.getMonth()]}
            <svg
              className="size-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          {monthOpen && (
            <div className="absolute top-full left-0 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
              <ScrollArea className="h-48">
                <div className="p-1">
                  {months.map((month, index) => (
                    <button
                      key={month}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMonthChange(index);
                        setMonthOpen(false);
                      }}
                      className={cn(
                        "w-full px-3 py-1.5 text-left rounded hover:bg-gray-100",
                        currentDate.getMonth() === index &&
                          "bg-blue-50 text-blue-600",
                      )}
                    >
                      {month}
                    </button>
                  ))}
                </div>
              </ScrollArea>
            </div>
          )}
        </div>

        <div className="relative w-32" ref={yearRef}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setYearOpen(!yearOpen);
            }}
            className="w-full p-2 pl-4 pr-8 rounded-lg border border-gray-200 bg-white cursor-pointer focus:outline-none focus:border-gray-300 flex items-center justify-between"
          >
            {currentDate.getFullYear()}
            <svg
              className="size-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          {yearOpen && (
            <div className="absolute top-full left-0 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
              <ScrollArea className="h-48">
                <div className="p-1">
                  {years.map((year) => (
                    <button
                      key={year}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleYearChange(year);
                        setYearOpen(false);
                      }}
                      className={cn(
                        "w-full px-3 py-1.5 text-left rounded hover:bg-gray-100",
                        currentDate.getFullYear() === year &&
                          "bg-blue-50 text-blue-600",
                      )}
                    >
                      {year}
                    </button>
                  ))}
                </div>
              </ScrollArea>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg">
        <div className="grid grid-cols-7 gap-1">
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
            <div key={day} className="p-1 text-sm text-center text-gray-600">
              {day}
            </div>
          ))}
          {renderCalendar()}
        </div>
      </div>
    </div>
  );
};

export interface DatePickerInputProps extends BaseField {
  type: "date" | "datetime";
}

export const DatePickerInput = withConditional<DatePickerInputProps>(
  ({ form, field }) => {
    const isDatetime = field.type === "datetime";

    return (
      <FormField
        control={form.control}
        name={field.name}
        rules={field.required ? { required: true } : undefined}
        defaultValue={field.defaultValue}
        render={({ field: formField }) => (
          <FormItem className="flex flex-col w-full">
            <FormLabel tooltip={field.tooltip}>{field.label}</FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    className={cn(
                      "w-full pl-3 text-left font-normal",
                      !formField.value && "text-muted-foreground",
                    )}
                  >
                    {formField.value ? (
                      format(
                        new Date(formField.value),
                        isDatetime ? "PPp" : "PP",
                      )
                    ) : (
                      <span>
                        <Trans>Pick a date</Trans>
                      </span>
                    )}
                    <CalendarIcon className="ml-auto size-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-4 z-[1500]" align="start">
                <DatePickerCalendar
                  value={formField.value}
                  onChange={formField.onChange}
                  isDatetime={isDatetime}
                />
              </PopoverContent>
            </Popover>
            <FormDescription>{field.description}</FormDescription>
            <FormMessage />
            <input hidden {...formField} value={formField.value || ""} />
          </FormItem>
        )}
      />
    );
  },
);

export default DatePickerInput;
