import "@components/RichTextEditor/styles.css";
import "jodit/es5/jodit.min.css";

import { i18n } from "@bleu.builders/ui";
import { FlipperProvider } from "@contexts/FlipperContext";
import { ParticipantProvider } from "@contexts/ParticipantContext";
import { ProgramProvider } from "@contexts/ProgramContext";
import ChallengePage from "@pages/programs/challenges/ChallengePage";
import ErrorBoundary from "@pages/programs/ErrorBoundary";
import HomePage from "@pages/programs/home/HomePage";
import EditEmailSettings from "@pages/programs/my-account/EditEmailSettings";
import { MyAccountPage } from "@pages/programs/my-account/MyAccountPage";
import { Photos } from "@pages/programs/photos/Index";
import { ProgramPage } from "@pages/programs/ProgramPage";
import { RewardPage } from "@pages/programs/rewards/RewardPage";
import { SignInPage } from "@pages/programs/signin/SignInPage";
import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import MerchantOffers from "../pages/programs/my-offers/MerchantOffers";
import Offers from "../pages/programs/my-offers/Offers";

const App = ({ participant, program, flipper_features }) => {
  useEffect(() => {
    i18n.default.changeLanguage("en");
  }, []);

  const programRoutes = program.published_program_pages.map((page) => ({
    path: `/${page.slug}`,
    element: <ProgramPage page={page} />,
  }));

  const router = createBrowserRouter([
    {
      path: "/",
      children: [
        {
          index: true,
          element: <HomePage />,
          loader: HomePage.loader,
        },
        ...programRoutes,
      ],
    },
    {
      path: "/signin",
      element: <SignInPage />,
      action: SignInPage.action,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/my-account",
      element: <MyAccountPage />,
      action: EditEmailSettings.action,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/challenge/:challenge_id",
      element: <ChallengePage />,
      loader: ChallengePage.loader,
      action: ChallengePage.action,
    },
    {
      path: "/reward/:reward_id",
      element: <RewardPage />,
      loader: RewardPage.loader,
      action: RewardPage.action,
    },
    {
      path: "/my-offers",
      element: <Offers />,
      loader: Offers.loader,
    },
    {
      path: "/my-offers/:merchant_slug",
      element: <MerchantOffers />,
      loader: MerchantOffers.loader,
    },
    {
      path: "/photos",
      element: <Photos />,
    },
  ]);

  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n.default}>
        <ProgramProvider program={program}>
          <ParticipantProvider participant={participant}>
            <FlipperProvider flipperFeatures={flipper_features}>
              <RouterProvider router={router} />
            </FlipperProvider>
          </ParticipantProvider>
        </ProgramProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

export default App;
